import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchWithAuth } from '../../../utils/fetchWithAuth';
import { Header } from '../../../components/header';
import { NoThanksHomePageRoom } from './room';
import { socket } from '../../../socket';
import { useUserStore } from '../../../store/useUserStore';
import { NoThanksHomePageRightSidebar } from './rightSidebar';

import './styles.css';

export const NoThanksHomePage = () => {
	const navigate = useNavigate();
	const { user } = useUserStore();
	const [isCreatingRoomDisabled, setIsCreatingRoomDisabled] = useState(false);
	const [rooms, setRooms] = useState<Record<string, any>[]>([]);
	const [ pendingRoom, setPendingRoom ] = useState<string | null>(null);

	const handleCreateRoom = async () => {
		setIsCreatingRoomDisabled(true);
		
		try {
			const response = await fetchWithAuth('/api/v1/no-thanks/create', { method: 'POST' });
			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.message);
			}
			const data = await response.json();
			// setRooms([ ...rooms, data ]);
			navigate(`/games/no-thanks/${data._id}`);
		} catch (error: any) {
			alert(error.message);
		} finally {
			setIsCreatingRoomDisabled(false);
		}
	};

	const handleStartGame = async (roomId: string) => {
		setPendingRoom(roomId);

		try {
			const response = await fetchWithAuth('/api/v1/no-thanks/start/' + roomId, { method: 'POST' });
			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.message);
			}

			navigate(`/games/no-thanks/${roomId}`);
		} catch (error: any) {
			alert(error.message);
		} finally {
			setPendingRoom(null);
		}
	};

	const handleDeleteRoom = async (roomId: string) => {
		setPendingRoom(roomId);

		try {
			const response = await fetchWithAuth('/api/v1/no-thanks/remove/' + roomId, { method: 'DELETE' });
			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.message);
			}

			const updatedRooms = rooms.filter((room) => room._id !== roomId);
			setRooms(updatedRooms);
		} catch (error: any) {
			alert(error.message);
		} finally {
			setPendingRoom(null);
		}
	};

	const handleJoinRoom = async (roomId: string) => {
		setPendingRoom(roomId);

		try {
			const response = await fetchWithAuth('/api/v1/no-thanks/join/' + roomId, { method: 'POST' });
			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.message);
			}

			navigate(`/games/no-thanks/${roomId}`);
		} catch (error: any) {
			alert(error.message);
		} finally {
			setPendingRoom(null);
		}
	};

	const handleLeaveRoom = async (roomId: string) => {
		setPendingRoom(roomId);

		try {
			const response = await fetchWithAuth('/api/v1/no-thanks/leave/' + roomId, { method: 'POST' });
			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.message);
			}

			const updatedRoom = await response.json();
			const updatedRooms = rooms.map((room) => room._id === roomId ? updatedRoom : room);
			setRooms(updatedRooms);
		} catch (error: any) {
			alert(error.message);
		} finally {
			setPendingRoom(null);
		}
	};

	useEffect(() => {
		socket.on('no-thanks: room updated', ( updatedRoom: any ) => {
			const existedRoom = rooms.find((room) => room._id === updatedRoom._id);
			if (existedRoom) {
				const updatedRooms = rooms.map((room) => room._id === updatedRoom._id ? updatedRoom : room);
				setRooms(updatedRooms);
			} else {
				setRooms([ ...rooms, updatedRoom ]);
			}
		});

		socket.on('no-thanks: room deleted', ( deletedRoomId: string ) => {
			const updatedRooms = rooms.filter((room) => room._id !== deletedRoomId);
			setRooms(updatedRooms);
		});
	
		return () => {
			socket.off('no-thanks: room updated');
			socket.off('no-thanks: room deleted');
		};
	}, [ rooms, setRooms ]);

	useEffect(() => {
		const response = fetchWithAuth('/api/v1/no-thanks/list');

		response
			.then((res) => res.json())
			.then((data) => setRooms(data))
			.catch((error) => console.error('Failed to fetch rooms:', error));
	}, []);

	if ( ! user ) return null;

	const myRooms = rooms.filter((room) => room.members.find((member: any) => member.memberId === user._id && member.isAdmin));
	const notMyRooms = rooms.filter((room) => ! room.members.find((member: any) => member.memberId === user._id && member.isAdmin));

	myRooms.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
	notMyRooms.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

	return (
		<>
			<Header />

			<div className="no-thanks-home-page">
				<div className="no-thanks-home-page__content">
					<h1 className="text-3xl text-center mb-4">No, Thanks!</h1>
					<button
						onClick={handleCreateRoom}
						className="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded block mx-auto"
						disabled={isCreatingRoomDisabled}
					>
						Create new room
					</button>

					<div className="space-y-4 block">
						{ ! rooms.length && (
							<div className="message-no-rooms">No rooms have been created yet<br />Create one and invite your friends</div>
						)}

						{ !! myRooms.length && (
							<>
								{ !! notMyRooms.length && <h2 className="text-xl bottom line border-b-2 border-gray-300 pb-2">My rooms</h2> }
								{
									myRooms.map((room, index) => (
										<NoThanksHomePageRoom
											key={ room._id }
											room={ room }
											isPending={ pendingRoom === room._id }
											onDelete={ handleDeleteRoom }
											onStartGame={ handleStartGame }
											onJoin={ handleJoinRoom }
											onLeave={ handleLeaveRoom }
										/>
									))
								}
							</>
						)}

						{ !! notMyRooms.length && (
							<>
								{ !! myRooms.length && <><br /><h2 className="text-xl bottom line border-b-2 border-gray-300 pb-2">Other rooms</h2></> }
								{
									notMyRooms.map((room, index) => (
										<NoThanksHomePageRoom
											key={ room._id }
											room={ room }
											isPending={ pendingRoom === room._id }
											onDelete={ handleDeleteRoom }
											onStartGame={ handleStartGame }
											onJoin={ handleJoinRoom }
											onLeave={ handleLeaveRoom }
										/>
									))
								}
							</>
						)}
					</div>
				</div>

				<NoThanksHomePageRightSidebar />
			</div>
		</>
	);
};