import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useUserStore } from '../../store/useUserStore';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';

export const PageSignIn: React.FC = () => {
	const [ formData, setFormData ] = useState( { email: '', password: '' } );
	const [ loginError, setLoginError ] = useState( '' );
	const { login } = useUserStore();
	const navigate = useNavigate();

	const handleChange = ( event: React.ChangeEvent< HTMLInputElement > ) => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		try {
			await login(formData.email, formData.password);

			const queryParams = queryString.parse(window.location.search);
			const redirect = queryParams.redirect || '/';
			navigate(redirect as string);
		} catch (error) {
			setLoginError((error as Error).message);
		}
	};

	return (
		<div className="page-simple">
			<Header />
			<div className="page-simple__content">
				<div className="px-8 py-6 mt-4 text-left bg-white shadow-lg rounded" style={{ width: '400px', position: 'relative', marginTop: 0 }}>
					<button
						onClick={() => navigate('/')}
						className="absolute top-5 left-5 p-2 text-blue-500 hover:text-blue-700 focus:outline-none"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M15 19l-7-7 7-7"
							/>
						</svg>
					</button>
					<h3 className="text-2xl font-bold text-center">Sign In</h3>
					{loginError && <p className="text-red-500">{loginError}</p>}
					<form onSubmit={handleSubmit} className="mt-4">
						<div>
							<label htmlFor="email" className="block">Email</label>
							<input
								type="email"
								placeholder="Email" 
								name="email"
								className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
								onChange={ handleChange }
								required
							/>
						</div>
						<div className="mt-4">
							<label htmlFor="password" className="block">Password</label>
							<input
								type="password"
								placeholder="Password"
								name="password"
								className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
								onChange={handleChange}
								required
							/>
						</div>
						<div className="flex items-center justify-between mt-4">
							<button
								type="submit"
								className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:bg-blue-600"
							>
								Submit
							</button>
						</div>
						<br />
						<div className="mt-4">
							<a href="/request-reset-password" className="text-blue-500 hover:underline">Forgot password?</a>
						</div>
					</form>
				</div>
			</div>
			<Footer />
		</div>
	);
};