import classNames from 'classnames';
import './styles.css';

const originalWidth = 150;
const originalHeight = 200;

type CardFrontProps = {
	value: number;
	width?: number;
	style?: React.CSSProperties;
	className?: string;
	dataId?: string;
};

export const CardFront = ({ value, width = originalWidth, style = {}, className = '', dataId }: CardFrontProps) => {
	const aspectRatio = originalHeight / originalWidth;
	const height = width * aspectRatio;

	const newStyle = {
		...style,
		'--card-width': `${width}px`,
		'--card-height': `${height}px`,
	} as React.CSSProperties;

	return (
		<div className={ classNames( 'card-front', className ) } style={ newStyle } data-id={ dataId }>
			<div className="card-front__group">
				<div className="card-front__value">{ value }</div>
				<div className="card-front__value--tl">{ value }</div>
				<div className="card-front__value--tr">{ value }</div>
			</div>
			<div className="card-front__group flipped">
				<div className="card-front__value">{ value }</div>
				<div className="card-front__value--tl">{ value }</div>
				<div className="card-front__value--tr">{ value }</div>
			</div>
		</div>
	);
};