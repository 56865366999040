import { NoThanksWebAudio } from '../../webAudioAPI';

export const preloadSounds = () => {
	NoThanksWebAudio.preloadSounds([
		`${process.env.REACT_APP_API_BASE_URL}/assets/no-thanks/shuffle.mp3`,
		`${process.env.REACT_APP_API_BASE_URL}/assets/no-thanks/chipDrop.mp3`,
		`${process.env.REACT_APP_API_BASE_URL}/assets/no-thanks/flip.mp3`,
	]);
};

export const playGameStartSound = () => {
	NoThanksWebAudio.playSound(`${process.env.REACT_APP_API_BASE_URL}/assets/no-thanks/shuffle.mp3`);
};

export const playChipThrownSound = () => {
	NoThanksWebAudio.playSound(`${process.env.REACT_APP_API_BASE_URL}/assets/no-thanks/chipDrop.mp3`);
};

export const playCardTakenSound = () => {
	NoThanksWebAudio.playSound(`${process.env.REACT_APP_API_BASE_URL}/assets/no-thanks/flip.mp3`);
};

export const playGameFinished = () => {
	NoThanksWebAudio.playSound(`${process.env.REACT_APP_API_BASE_URL}/assets/no-thanks/hooray.mp3`);
};