import io, { Socket as SocketIOClient } from 'socket.io-client';
import { toast } from 'react-toastify';

class Socket {
	private socket: SocketIOClient | undefined;

	initialize() {
		if ( this.socket ) return;

		this.socket = io(process.env.REACT_APP_API_BASE_URL!, {
			auth: {
				token: localStorage.getItem('token'),
			},
			path: '/socket.io/',
		});
		
		this.socket.on('connect', function() {
			console.log('Socket is connected to server');
		});

		this.socket.on('connect_error', function(error: Error) {
			console.log('Socket connection error:', error);
		});
		
		this.socket.on('disconnect', ( reason ) => {
			console.log('Socket is disconnected from server');

			if (reason === 'io server disconnect') {
				// the disconnection was initiated by the server, you need to reconnect manually
				this.socket?.connect();
			}

			// else the socket will automatically try to reconnect
			toast.error('You are disconnected from the server, we are trying to reconnect you', {
				position: "bottom-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		});

		socket.on('reconnect_attempt', ( attemptNumber: number ) => {
			toast.info(`Trying to reconnect to the server. Attempt number ${attemptNumber}.`, {
				position: "bottom-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		});

		socket.on('reconnect', ( attemptNumber: number ) => {
			toast.info(`Reconnected to the server after ${attemptNumber} attempts.`, {
				position: "bottom-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		});

		socket.on('reconnect_error', (error: Error) => {
			console.log(error);

			toast.error('Reconnection error. Trying again...', {
				position: "bottom-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		});

		socket.on('reconnect_failed', () => {
			toast.error('Failed to reconnect to the server. Please check your connection.', {
				position: "bottom-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		});
		
		this.socket.on('hello', ( message: string) => {
			console.log(message);
		
			this.socket?.off('hello');
		});
	}

	on(event: string, fn: Function) {
		const wrappedFunction = (...args: any[]) => {
			console.log(`Event received: ${event}`, args);
			fn(...args);
		};
		this.socket?.on(event, wrappedFunction);
	}

	off(event: string) {
		this.socket?.off(event);
	}

	destroy() {
		this.socket?.disconnect();
		this.socket = undefined;
	}
}

export const socket = new Socket();