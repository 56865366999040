import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useUserStore } from '../../store/useUserStore';
import { isSoundTurnedOn } from '../../utils/isSoundTurnedOn';

import './styles.css';

export const Header = () => {
	const { user, logout } = useUserStore();
	const [ isSoundOn, setIsSoundOn ] = useState( isSoundTurnedOn() );

	const handleToggleSound = () => {
		setIsSoundOn( ! isSoundOn );

		window.localStorage.setItem( 'ggwp-sound-status', JSON.stringify( ! isSoundOn ) );
	};

	let className = "header bg-blue-500 text-white h-24 flex justify-between items-center px-10";
	if ( ! user ) {
		className += " header--logged-out";
	}

	return (
		<header className={ className }>
			<Link to="/" title="Good Game Well Played">
				<div className="logo" />
			</Link>
			{
				user && (
					<div className="flex items-center space-x-4">
						
						<Link to="/me" className="hover:text-blue-300">Profile</Link>
						<button className="hover:text-blue-300" onClick={logout}>Logout</button>
						<button
							className="rounded-full flex items-center justify-center w-8 h-8 bg-black bg-opacity-10"
							onClick={ handleToggleSound }
							title="Toggle sound"
						>
							{ isSoundOn ? '🔊' : '🔇' }
						</button>
					</div>
				)
			}
		</header>
	);
};