import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useUserStore } from '../../store/useUserStore';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';

export const ResetPassword: React.FC = () => {
	const { token } = useParams();
	const location = useLocation();
	const queryParams = queryString.parse( location.search );
	const [ formData, setFormData ] = useState({ password: '' });
	const [ isPasswordReset , setIsPasswordReset ] = useState<boolean>();
	const [ serverError, setServerError ] = useState('');
	const { resetPassword } = useUserStore();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		try {
			await resetPassword( formData.password, token as string, queryParams.email as string );
			setIsPasswordReset( true );
			setServerError( '' );
		} catch (error) {
			setServerError( (error as Error).message );
		}
	};

	return (
		<div className="page-simple">
			<Header />
			<div className="page-simple__content">
				<div className="px-8 py-6 mt-4 text-left bg-white shadow-lg rounded" style={{ width: '400px', position: 'relative', marginTop: 0 }}>
					<h3 className="text-2xl font-bold text-center">Reset Password</h3>

					{ serverError && <p className="text-red-500">{ serverError }</p> }

					{ isPasswordReset && (
						<div className="mt-4">
							<p>
								Password reset successfully. You can now <a href="/signin" className="text-blue-500">sign in</a> with your new password.
							</p>
						</div>
					)}

					{ ! isPasswordReset && (
						<form onSubmit={handleSubmit} className="mt-4">
							<div>
								<label htmlFor="email" className="block">New Password</label>
								<input
									type="password"
									placeholder="Enter new password"
									name="password"
									className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
									onChange={ handleChange }
									required
								/>
							</div>
							<div className="flex items-center justify-between mt-4">
								<button
									type="submit"
									className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:bg-blue-600"
								>
									Reset
								</button>
							</div>
						</form>
					)}
				</div>
			</div>
			<Footer />
		</div>
	);
};