import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useUserStore } from '../../../store/useUserStore';
import { NO_THANKS_MIN_PLAYERS } from '../constants';

import './styles.css';

type NoThanksHomePageRoomProps = {
	room: any;
	isPending: boolean;
	onDelete: (roomId: string) => void;
	onStartGame: (roomId: string) => void;
	onJoin: (roomId: string) => void;
	onLeave: (roomId: string) => void;
};

export const NoThanksHomePageRoom = ({
	room,
	isPending,
	onDelete,
	onStartGame,
	onJoin,
	onLeave,
}: NoThanksHomePageRoomProps) => {
	const { user } = useUserStore();

	const amIAdmin = room.members.find((member: any) => member.memberId === user?._id)?.isAdmin;
	const amIInTheRoom = room.members.find((member: any) => member.memberId === user?._id);

	let buttons;

	if (amIAdmin) {
		buttons = (
			<>
				{ room.members.length < NO_THANKS_MIN_PLAYERS && (
					<span className="text-gray-500 text-sm">(You need at least { NO_THANKS_MIN_PLAYERS } players to start the game)</span>
				)}
				<button
					onClick={() => onStartGame(room._id)}
					className={
						classNames(
							'bg-blue-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded',
							{ 'opacity-30 pointer-events-none': room.members.length < NO_THANKS_MIN_PLAYERS }
						)
					}
					disabled={room.members.length < NO_THANKS_MIN_PLAYERS}
				>
					Start
				</button>
				<button
					onClick={() => onDelete(room._id)}
					className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
				>
					Delete
				</button>
			</>
		);
	} else  if (!amIInTheRoom){
		buttons = (
			<button
				onClick={() => onJoin(room._id)}
				className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded"
			>
				Join
			</button>
		);
	} else if (amIInTheRoom){
		buttons = (
			<button
				onClick={() => onLeave(room._id)}
				className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
			>
				Leave
			</button>
		);
	}

	const roomUrl = `${window.location.origin}/games/no-thanks/${room._id}`;

	const copyUrlToClipboard = () => {
		navigator.clipboard.writeText( roomUrl );

		toast.success("URL Copied!", {
			position: "top-center",
			autoClose: 2000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	return (
		<div key={room._id} className={
			classNames('p-4 border border-gray-200 rounded-lg shadow space-y-2', {
				'opacity-50': isPending,
				'pointer-events-none': isPending,
			})
		}>
			<div
				className="flex items-center"
				style={{ flexWrap: 'wrap' }}
			>
				<button onClick={copyUrlToClipboard} className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded py-0 px-2 text-sm py-0.5" style={{ marginRight: '5px' }}>Copy URL</button>
				<p className="text-sm text-gray-500"> (copy and share the URL with your friends)</p>
				<div className="text-sm text-gray-500" style={{ marginLeft: 'auto' }}>{new Date(room.createdAt).toLocaleString()}</div>
			</div>
			<ol>
				{room.members.map((member: any) => (
					<li key={member.memberId}>
						<div
							className="flex items-center"
						>
							{member.isAdmin
								? <>
									<b>{member.nickname}</b>
									 (admin)
								</>
								: member.nickname}
						</div>
					</li>
				))}
			</ol>

			<div className="flex items-center justify-between">
				<p>Players: {room.members.length}</p>
				<div className="space-x-2">
					{buttons}
				</div>
			</div>
		</div>
	)
};