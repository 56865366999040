import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Footer } from '../../components/footer';

import './styles.css';

export const PageHomeLanding: React.FC = () => {
	const navigate = useNavigate();

	const handleSignInClick = () => {
		navigate('/signin');
	};

	const handleSignUpClick = () => {
		navigate('/signup');
	};

	return (
		<div className="page-simple">
			<div className="page-simple__content">
				<div>
					<div className="home-logo" />
					<div className="home-buttons">
						<button 
							onClick={handleSignInClick}
							className="text-white bg-blue-700 hover:bg-blue-800 font-semibold py-2 px-4 border border-blue-700 rounded shadow"
						>
							Sign In
						</button>
						<button 
							onClick={handleSignUpClick}
							className="text-white bg-green-700 hover:bg-green-800 font-semibold py-2 px-4 border border-green-700 rounded shadow"
						>
							Sign Up
						</button>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};