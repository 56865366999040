import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import { NO_THANKS_MIN_PLAYERS } from '../../games/NoThanks/constants';

import './styles.css';

export const RulesNoThanks = () => {
	return (
		<div className="page-simple">
			<Header />
			<div className="page-simple__content">
				<div className="page-rules-no-thanks__body">
					<h1 className="text-3xl font-bold text-center">No, Thanks! Game Rules</h1>

					<h2 className="text-2xl font-bold">Objective</h2>
					<p>
						The goal in No Thanks! is to score as few points as possible.
					</p>

					<h2 className="text-2xl font-bold">Setup</h2>
					<p>
						Minutes: 10-15<br />
						Number of Players: { NO_THANKS_MIN_PLAYERS }-7<br />
						Shuffle the deck of 33 cards, numbered 3-35.<br />
						Remove 9 cards randomly and place them back in the box; they will not be used for this game.<br />
						Deal each player 11 chips. Chips are valuable as they help you avoid taking cards.<br />
					</p>

					<h2 className="text-2xl font-bold">Game Play</h2>
					<p>
						Starting the Game: The youngest player starts and play proceeds clockwise.<br />
						On Your Turn: A card is revealed from the deck. You must either:<br />
						-- Take the card along with any chips on it, or<br />
						--Place one of your chips on the card and pass to the next player.<br />
						Passing: If you pass, you must place one of your chips on the card. The turn then passes to the next player. This continues until a player takes the card (and all chips on it).<br />
						Taking a Card: If you take a card, you add it face-up in front of you (stacking any duplicate numbers) and also collect all chips placed on the card. Reveal the next card to start a new round.<br />
					</p>

					<h2 className="text-2xl font-bold">Scoring</h2>
					<p>
						Card Points: Each card you have is worth its face value in points.<br />
						Series of Cards: If you have a sequence of consecutive numbers, only the lowest card in the sequence counts.<br />
						Chips: Each chip you have is worth -1 point.<br />
						Example: If you have the cards 10, 11, 12, and 14 with 3 chips, your score is 10 (for the series 10-12) + 14 - 3 = 21 points.<br />
					</p>

					<h2 className="text-2xl font-bold">End of the Game</h2>
					<p>
						The game ends when all cards have been drawn and either taken or passed on. Players then calculate their scores. The player with the lowest score wins.
					</p>

					<h2 className="text-2xl font-bold">Tips</h2>
					<p>
						<ul>
							<li>It's often better to pass and force others to use their chips, but be careful not to be forced into taking a high card with no chips.</li>
							<li>Try to collect sequences to minimize points.</li>
							<li>Manage your chips wisely; they are as important as the cards.</li>
						</ul>
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
};