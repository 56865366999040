import { create } from 'zustand';
import { fetchWithAuth } from '../utils/fetchWithAuth';
import { socket } from '../socket';

interface User {
	_id: string;
	email: string;
	nickname: string;
	avatar?: string;
}

interface UserState {
	user: User | null;
	isLoadingUser: boolean;
	register: ( email: string, password: string, nickname: string ) => Promise< void >;
	login: ( email: string, password: string ) => Promise<void>;
	logout: () => void;
	requestResetPassword: ( email: string ) => Promise<void>;
	resetPassword: ( password: string, token: string, email: string ) => Promise< void >;
	fetchUserInfo: () => Promise< void >;
	updateUserInfo: ( { nickname, avatar }: { nickname?: string; avatar?: File; } ) => Promise< void >;
}

export const useUserStore = create<UserState>((set) => ({
	user: null,
	isLoadingUser: true,
	register: async (email: string, password: string, nickname: string) => {
		set({ isLoadingUser: true });

		try {
			const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/register`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email, password, nickname }),
			});
			
			if (!response.ok) {
				const { errors } = await response.json();
				const message = errors.map((error: { msg: string }) => error.msg).join(' ');
				throw new Error(message);
			}

			const data = await response.json();
			localStorage.setItem('token', data.token);
			set({ user: data.user })

			socket.initialize();
		} finally {
			set({ isLoadingUser: false });
		}
	},
	login: async (email: string, password: string) => {
		set({ isLoadingUser: true });

		try {
			const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/login`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email, password }),
			});

			if (!response.ok) {
				throw new Error(await response.text());
			}

			const data = await response.json();
			localStorage.setItem('token', data.token);
			set({ user: data.user })

			socket.initialize();
		} finally {
			set({ isLoadingUser: false });
		}
	},
	logout: () => {
		localStorage.removeItem('token');

		set({ user: null })

		socket.destroy();
	},
	requestResetPassword: async (email: string) => {
		set({ isLoadingUser: true });

		try {
			const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/request-reset-password`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email }),
			});

			if (!response.ok) {
				throw new Error(await response.text());
			}
		} finally {
			set({ isLoadingUser: false });
		}
	},
	resetPassword: async (password: string, token: string, email: string) => {
		set({ isLoadingUser: true });

		try {
			const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/reset-password`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ password, token, email }),
			});

			if (!response.ok) {
				throw new Error(await response.text());
			}
		} finally {
			set({ isLoadingUser: false });
		}
	},
	fetchUserInfo: async () => {
		set({ isLoadingUser: true });

		try {
			const response = await fetchWithAuth('/api/v1/user');
			if (!response.ok) {
				throw new Error(await response.text());
			}
			const data = await response.json();

			set({ user: data.user });

			socket.initialize();
		} catch (error: any) {
			console.error(error);
		} finally {
			set({ isLoadingUser: false });
		}
	},
	updateUserInfo: async ( body ) => {
		set({ isLoadingUser: true });

		try {
			const formData = new FormData();
			for (const [ key, value ] of Object.entries(body)) {
				formData.append(key, value);
			}
			
			const response = await fetchWithAuth('/api/v1/user', {
				method: 'PATCH',
				body: formData,
			});

			if (!response.ok) {
				if ( response.status === 413 && response.statusText === 'Request Entity Too Large' ) {
					throw new Error('File size is too large. Please upload a file that is smaller than 10mb.');
				}

				throw new Error(await response.text());
			}

			const data = await response.json();
			set({ user: data.user });
		} finally {
			set({ isLoadingUser: false });
		}
	}
}));