import { useRef, useEffect, useState } from 'react';
import { Coin } from '../Coin';
import { useRoomStore } from '../../store/useNoThanksRoomStore';

import './styles.css';

type CardEmptyProps = {
	count: number;
};

const COIN_SIZE = 50;

export const CardEmpty = ({ count }: CardEmptyProps) => {
	const { room } = useRoomStore();
	const areaRef = useRef<HTMLDivElement>(null);
	const [isInitialRender, setIsInitialRender] = useState( true );
	const [transitions, setTransitions] = useState<string[]>( [] );

	useEffect(() => {
		if ( ! room || ! areaRef.current ) return;

		const areaWidth = areaRef.current.offsetWidth - COIN_SIZE;
		const areaHeight = areaRef.current.offsetHeight - COIN_SIZE;

		if (isInitialRender) {
			setIsInitialRender(false);

			if ( count ) {
				setTransitions(
					Array.from({ length: count }).map(() => {
						return `translate(${Math.random() * areaWidth}px, ${Math.random() * areaHeight}px)`;
					})
				);
			}
			return;
		}

		const newUser = document.querySelector(`.right-sidebar__user_${room.turnUserId}`) as HTMLElement;
		const previousUser = newUser.previousElementSibling || document.querySelector('.right-sidebar__member:last-child') as HTMLElement;


		const lastCoin = document.querySelector('.card-empty__area .no-thanks__coin:last-child') as HTMLElement;
		if (!lastCoin) return;

		const x = previousUser.getBoundingClientRect().left  - areaRef.current.getBoundingClientRect().left ;
		const y = previousUser.getBoundingClientRect().top  - areaRef.current.getBoundingClientRect().top;

		lastCoin.style.transform = `translate(${x}px, ${y}px)`;
		
		setTimeout(() => {
			lastCoin.style.transition = 'transform 1.5s cubic-bezier(0.19, 1, 0.22, 1)';

			setTransitions([
				...transitions,
				`translate(${Math.random() * areaWidth}px, ${Math.random() * areaHeight}px)`,
			]);
		}, 50);

	// TODO: investigate. I don't remember was it intentional list of deps or not
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ room?.turnUserId ] );

	useEffect(() => {
		if ( count !== 0 ) return;

		setTransitions([]);
	}, [ count ]);

	return (
		<div className="card-empty">
			<div className="card-empty__area" ref={ areaRef }>
				{
					Array.from({ length: count }).map((_, i) => {
						return (
							<Coin
								key={ i }
								size={ COIN_SIZE }
								style={{
									position: 'absolute',
									transform: transitions[i],
								}}
							/>
						);
					})
				}
			</div>
			{count}
		</div>
	);
};