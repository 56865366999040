export const fetchWithAuth = async (url: string, options: RequestInit = {}): Promise<Response> => {
	const token = localStorage.getItem('token');
	if (!token) {
		throw new Error('No token found');
	}

	const headers = new Headers(options.headers || {});
	headers.append('Authorization', `Bearer ${token}`);

	const updatedOptions: RequestInit = { ...options, headers };

	let response;
	try {
		response = await fetch(process.env.REACT_APP_API_BASE_URL + url, updatedOptions);
	} catch (error) {
		throw new Error('Failed to fetch');
	}
	
	return response;
};