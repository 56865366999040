import { isSoundTurnedOn } from './utils/isSoundTurnedOn';

const audioContext = new AudioContext();

export class NoThanksWebAudio {
	static instance: NoThanksWebAudio;

	constructor() {
		if (NoThanksWebAudio.instance) {
			return NoThanksWebAudio.instance;
		}

		NoThanksWebAudio.instance = this;
	}

	private buffers: Record<string, AudioBuffer> = {};

	private loadSound = ( audioUrl: string ) => {
		return fetch( audioUrl )
			.then( response => response.arrayBuffer() )
			.then( arrayBuffer => audioContext.decodeAudioData( arrayBuffer ) )
			.then( buffer => {
				this.buffers[audioUrl] = buffer;
			});
	};

	static preloadSounds = ( sounds: string[] ) => {
		if ( ! this.instance ) {
			this.instance = new NoThanksWebAudio();
		}

		sounds.forEach( sound => {
			this.instance.loadSound(sound);
		});
	}

	static playSound = async ( audioUrl: string ) => {
		if ( ! this.instance ) {
			this.instance = new NoThanksWebAudio();
		}

		if ( ! isSoundTurnedOn() ) return;

		if ( ! this.instance.buffers[audioUrl] ) {
			await this.instance.loadSound(audioUrl);
		}

		const source = audioContext.createBufferSource();
		source.buffer = this.instance.buffers[audioUrl];
		source.connect( audioContext.destination );
		source.start();
	};
}