import { Header } from '../../components/header';
import { Footer } from '../../components/footer';

import './styles.css';

export const Page404 = () => {
	return (
		<div className="page-simple">
			<Header />
			<div className="page-simple__content">
				<div className="page-404">
					<h1 className="text-8xl font-bold text-center">404</h1>
					<br />
					<p className="text-center text-xl">
						We are sorry, but the page you are looking for does not exist.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
};