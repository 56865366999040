import { create } from 'zustand';
import { socket } from '../../../socket';
import { fetchWithAuth } from '../../../utils/fetchWithAuth';

export type MemberInRoom = {
	memberId: string;
	memberTotalScoreBeforeGame: number;
	nickname: string;
	avatar?: string;
	isAdmin: boolean;
	chips: number;
	pickedCards: number[];
	gameResults?: {
		cardsScore: number;
		chipsScore: number;
		currentGameScore: number;
		newTotalScore: number;
	}
};

type RoomStore = {
	room: {
		_id: string;
		createdAt: Date;
		state: 'waiting' | 'in-progress' | 'finished';
		chipsOnTable: number;
		turnUserId: string;
		members: MemberInRoom[];
		currentCard: number;
		cardsLeft: number;
	} | null;
	initialize: (roomId: string) => Promise<void>;
	destroy: (roomId: string) => void;
	api: {
		deleteRoom: (roomId: string) => Promise<void>;
		startGame: (roomId: string) => Promise<void>;
		joinRoom: (roomId: string) => Promise<void>;
		leaveRoom: (roomId: string) => Promise<void>;
		takeCard: (roomId: string) => Promise<void>;
		skipCard: (roomId: string) => Promise<void>;
	};
};

export const useRoomStore = create<RoomStore>((set) => ({
	room: null,
	initialize: async (roomId: string) => {
		const response = await fetchWithAuth('/api/v1/no-thanks/list/' + roomId);
		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.message);
		}
		const data = await response.json();

		set({ room: data });

		socket.on(`no-thanks[${data._id}]: room updated`, ( updatedRoom: RoomStore['room'] ) => {
			set({ room: updatedRoom });
		});
		
		socket.on(`no-thanks[${data._id}]: room deleted`, () => {
			alert('The room is deleted');
			window.location.href = '/games/no-thanks';
		});
	},
	destroy: (roomId: string) => {
		socket.off(`no-thanks[${roomId}]: room updated`);
		socket.off(`no-thanks[${roomId}]: room deleted`);
		set({ room: null });
	},
	api: {
		deleteRoom: async (roomId: string) => {
			const response = await fetchWithAuth('/api/v1/no-thanks/remove/' + roomId, { method: 'DELETE' });
			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.message);
			}

			set({ room: null });
		},
		startGame: async (roomId: string) => {
			const response = await fetchWithAuth('/api/v1/no-thanks/start/' + roomId, { method: 'POST' });
			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.message);
			}
		},
		joinRoom: async (roomId: string) => {
			const response = await fetchWithAuth('/api/v1/no-thanks/join/' + roomId, { method: 'POST' });
			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.message);
			}
		},
		leaveRoom: async (roomId: string) => {
			const response = await fetchWithAuth('/api/v1/no-thanks/leave/' + roomId, { method: 'POST' });
			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.message);
			}

			const updatedRoom = await response.json();

			set({ room: updatedRoom });
		},
		takeCard: async (roomId: string) => {
			const response = await fetchWithAuth(`/api/v1/no-thanks/take-card/${roomId}`, { method: 'POST' });
			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.message);
			}

			const updatedRoom = await response.json();

			set({ room: updatedRoom });
		},
		skipCard: async (roomId: string) => {
			const response = await fetchWithAuth(`/api/v1/no-thanks/skip-card/${roomId}`, { method: 'POST' });
			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.message);
			}

			const updatedRoom = await response.json();

			set({ room: updatedRoom });
		}
	},
}));