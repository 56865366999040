import { useState, useRef } from 'react';
import { useUserStore } from '../../store/useUserStore';
import { Header } from '../../components/header';
import { getAvatarUrl } from '../../utils/getAvatarUrl';

export const PageMe: React.FC = () => {
	const { user, updateUserInfo } = useUserStore();
	const [ serverError, setServerError ] = useState( '' );
	const [ formData, setFormData ] = useState({
		nickname: user?.nickname,
	});

	const [ fileAvatar, setFileAvatar ] = useState< File >();
	const [ tmpAvatar, setTmpAvatar ] = useState< string >();
	const inputAvatarRef = useRef<HTMLInputElement>(null);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];

		if ( ! file ) {
			return;
		}

		const reader = new FileReader();

		reader.onload = () => {
			setFileAvatar( file );
			setTmpAvatar(reader.result as string);
		};

		reader.readAsDataURL(file);
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setServerError( '' );

		try {
			await updateUserInfo( {
				...formData,
				avatar: fileAvatar,
			} );
		} catch (error) {
			setServerError((error as Error).message);
		} finally {
			setTmpAvatar( undefined );
			setFileAvatar( undefined );
			inputAvatarRef.current && ( inputAvatarRef.current.value = '' );
		}
	};

	const avatarPreview = tmpAvatar ?? getAvatarUrl( user?.avatar );

	const isSubmitDisabled = formData.nickname === user?.nickname && !fileAvatar;

	return (
		<>
			<Header />
			<div className="px-8 pt-6 pb-8 mb-4">
				<h2 className="block text-gray-700 text-xl font-bold mb-2">User Profile</h2>
				{ serverError && <p className="text-red-500">{ serverError }</p> }
				<form className="mt-4" onSubmit={ handleSubmit }>
					<p className="text-gray-700 text-base">
						<span className="font-semibold">Email:</span> {user?.email}
						<span className='ml-2'>(<a href="/request-reset-password?prev-url=/me" className="text-blue-500">Reset Password</a>)</span>
					</p>
					<br />
					<p className="flex items-center text-gray-700 text-base">
						<label htmlFor="nickname" className="mr-2 font-semibold">Nickname:</label>
						<input
							type="text"
							placeholder="Nickname"
							name="nickname"
							value={ formData.nickname }
							className="px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
							style={{ display: 'inline-block' }}
							onChange={ handleChange }
							required
						/>
					</p>
					<br />
					<p className="text-gray-700 text-base flex items-center mt-4">
						<label htmlFor="avatar" className="mr-2 font-semibold">Avatar: </label>
						{ avatarPreview && <div style={{ backgroundImage: `url(${ avatarPreview })` }} className="w-16 h-16 bg-cover bg-center rounded-full mr-2" /> }
						<input
							type="file"
							name="avatar"
							ref={ inputAvatarRef }
							accept="image/*"
							onChange={ handleAvatarChange }
						/>
					</p>
					<br />
					<div className="flex items-center justify-between mt-4">
						<button
							type="submit"
							className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:bg-blue-600 disabled:opacity-25 disabled:cursor-not-allowed disabled:bg-blue-500"
							disabled={ isSubmitDisabled }
						>
							Update
						</button>
					</div>
				</form>
			</div>
		</>
	);
};