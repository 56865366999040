import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../../store/useUserStore';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';

export const PageSignUp: React.FC = () => {
	const [formData, setFormData] = useState({ email: '', nickname: '', password: '' });
	const [registerError, setRegisterError] = useState('');
	const { register } = useUserStore();
	const navigate = useNavigate();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		try {
			await register(formData.email, formData.password, formData.nickname);
			navigate('/');
		} catch (error) {
			setRegisterError((error as Error).message);
		}
	};

	return (
		<div className="page-simple">
			<Header />
			<div className="page-simple__content">
				<div className="px-8 py-6 mt-4 text-left bg-white shadow-lg rounded" style={{ width: '400px', position: 'relative', marginTop: 0 }}>
					<button
						onClick={() => navigate('/')}
						className="absolute top-5 left-5 p-2 text-blue-500 hover:text-blue-700 focus:outline-none"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M15 19l-7-7 7-7"
							/>
						</svg>
					</button>
					<h3 className="text-2xl font-bold text-center">Register</h3>
					{registerError && <p className="text-red-500">{registerError}</p>}
					<form onSubmit={handleSubmit}>
						<div className="mt-4">
							<div>
								<label className="block" htmlFor="email">Email</label>
								<input
									type="email"
									placeholder="Email" 
									name="email"
									onChange={handleChange}
									className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
									required
								/>
							</div>
							<div className="mt-4">
								<label className="block" htmlFor="nickname">Nickname</label>
								<input
									type="text"
									placeholder="Nickname"
									name="nickname"
									onChange={handleChange}
									className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
									required
								/>
							</div>
							<div className="mt-4">
								<label className="block" htmlFor="password">Password</label>
								<input
									type="password"
									placeholder="Password"
									name="password"
									onChange={handleChange}
									className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
									required
								/>
							</div>
							<div className="flex items-baseline justify-between">
								<button className="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900">Submit</button>
							</div>
							<br />
							<div className="mt-4">
								<a href="/request-reset-password" className="text-blue-500 hover:underline">Forgot password?</a>
							</div>
						</div>
					</form>
				</div>
			</div>
			<Footer />
		</div>
	);
};