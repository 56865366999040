import { useEffect, useState } from "react";
import { fetchWithAuth } from "../../../utils/fetchWithAuth";

export const NoThanksHomePageRightSidebar = () => {
	const [ statistic, setStatistic ] = useState<any | null>(null);

	useEffect(() => {
		(async () => {
			const response = await fetchWithAuth('/api/v1/no-thanks/statistic');
			const data = await response.json();
			setStatistic(data);
		})();
	}, []);

	if (!statistic) return null;

	return (
		<div className="no-thanks-home-page__right-sidebar">
			<div className="no-thanks-home-page__right-sidebar__title">Best players</div>

			<div className="no-thanks-home-page__right-sidebar__list">
				{statistic.map((user: any, index: number) => (
					<div key={user.nickname} className="no-thanks-home-page__right-sidebar-list-item">
						<span className="no-thanks-home-page__right-sidebar-list-item-nickname">
							{index + 1}. {user.nickname}
						</span>
						<span className="no-thanks-home-page__right-sidebar-list-item-score" title={ `Games: ${user.numberOfGames}` }>
							<span className="no-thanks-home-page__right-sidebar-list-item-score-img"></span>
							{user.score} points
						</span>
					</div>
				))}
			</div>
		</div>
	);
}