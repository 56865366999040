import './styles.css';

type CardBackProps = {
	count: number | null;
};

export const CardBack = ({ count }: CardBackProps) => {
	return (
		<div className="card-back">
			<div className="card-back__body">
				<div className="card-back__number">{count}</div>
				{ count !== null && <div className="card-back__text">cards left</div> }
			</div>
		</div>
	);
};