export const DebercBattlePage: React.FC = () => {
	return (
		<div className="flex flex-col items-center justify-center h-full">
			<h1 className="text-4xl font-bold">Deberc</h1>
			<div className="flex flex-col space-y-4">
				BattlePage
			</div>
		</div>
	);
};
