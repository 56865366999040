import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useUserStore } from '../../../../../store/useUserStore';
import { useRoomStore } from '../../../store/useNoThanksRoomStore';
import { NO_THANKS_MIN_PLAYERS } from '../../../constants';

import './styles.css';

export const BattlePageDialogWaiting = () => {
	const navigate = useNavigate();
	const { user } = useUserStore();
	const { room, api } = useRoomStore();
	const [ pendingRoom, setPendingRoom ] = useState<string | null>(null);

	if ( ! room ) return null;

	const roomId = room._id;

	const handleButton = async ( action: 'start' | 'delete' | 'join' | 'leave' ) => {
		setPendingRoom(roomId);

		try {
			if (action === 'start') {
				await api.startGame(roomId);
			} else if (action === 'delete') {
				await api.deleteRoom(roomId);
			} else if (action === 'join') {
				await api.joinRoom(roomId);
			} else if (action === 'leave') {
				await api.leaveRoom(roomId);
			}
		} catch (error: any) {
			alert(error.message);
		} finally {
			setPendingRoom(null);
		}
	};

	const amIAdmin = room.members.find((member: any) => member.memberId === user?._id)?.isAdmin;
	const amIInTheRoom = room.members.find((member: any) => member.memberId === user?._id);

	let buttons;

	if (amIAdmin) {
		buttons = (
			<>
				<button
					onClick={() => handleButton('start')}
					className={
						classNames(
							'bg-blue-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded',
							{ 'opacity-30 pointer-events-none': room.members.length < NO_THANKS_MIN_PLAYERS }
						)
					}
					disabled={room.members.length < NO_THANKS_MIN_PLAYERS}
				>
					Start
				</button>
				<button
					onClick={() => handleButton('delete')}
					className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
				>
					Delete
				</button>
			</>
		);
	} else  if (!amIInTheRoom){
		buttons = (
			<button
				onClick={() => handleButton('join')}
				className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded"
			>
				Join
			</button>
		);
	} else if (amIInTheRoom){
		buttons = (
			<button
				onClick={() => handleButton('leave')}
				className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
			>
				Leave
			</button>
		);
	}

	const roomUrl = `${window.location.origin}/games/no-thanks/${room._id}`;

	const copyUrlToClipboard = () => {
		navigator.clipboard.writeText( roomUrl );
		toast.success("URL Copied!", {
			position: "top-center",
			autoClose: 2000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	const handleClose = () => {
		navigate(`/games/no-thanks`);
	};

	return (
		<div className="no-thanks-dialog-waiting">
			<div className="no-thanks-dialog-waiting__inner">
				<div className={classNames({ 'opacity-50': pendingRoom, 'pointer-events-none': pendingRoom })}>
					<h1 className="text-xl font-bold">Waiting for the admin ({ room.members.find((member) => member.isAdmin)?.nickname }) to start the game</h1>
					<br />
					<div className="no-thanks-dialog-waiting__close" onClick={ handleClose }>
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
						</svg>
					</div>
					<button onClick={copyUrlToClipboard} className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded py-1 px-2" style={{ marginRight: '5px' }}>Copy URL</button>
					<p className="text-sm text-gray-500"> (copy and share the URL with your friends)</p>

					<br /><br />

					<p className="text-lg font-bold">Number of Players: { room.members.length }</p>
					<p>
						Participants: {
							room.members.map((member) => member.nickname).join(', ')
						}
					</p>

					<br />

					<div className="no-thanks-dialog-waiting__buttons">
						{ buttons }
					</div>
					{ room.members.length < NO_THANKS_MIN_PLAYERS && (
						<div className="text-gray-500 text-sm">(You need at least { NO_THANKS_MIN_PLAYERS } players to start the game)</div>
					)}
				</div>
			</div>
		</div>
	);
};