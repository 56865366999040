import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useUserStore } from '../store/useUserStore';
import queryString from 'query-string';

export const PR = ( { children }: { children: React.ReactElement } ) => {
	const { fetchUserInfo, user, isLoadingUser } = useUserStore();

	useEffect( () => {
		if ( user ) return;

		fetchUserInfo();
	}, [ fetchUserInfo, user ] );

	if ( isLoadingUser && ! user ) return null;

	if (user)  {
		return children;
	} else {
		const queryParams = queryString.stringify( { redirect: window.location.pathname } );

		return <Navigate to={ `/signin?${queryParams}` } />;
	}
};
