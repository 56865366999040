import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import confetti from 'canvas-confetti';
import { useRoomStore, MemberInRoom } from "../../../store/useNoThanksRoomStore";
import { useUserStore } from '../../../../../store/useUserStore';
import { getAvatarUrl } from '../../../../../utils/getAvatarUrl';
import { playGameFinished } from '../../../sounds';

import './styles.css';

export const BattlePageDialogFinished = () => {
	const navigate = useNavigate();
	const { user } = useUserStore();
	const { room } = useRoomStore();

	useEffect(() => {
		playGameFinished();

		confetti({
			particleCount: 100, 
			spread: 70,
			origin: { y: 0.6 }
		});

		setTimeout(() => {
			confetti({
				particleCount: 100,  // More particles
				spread: 120,         // Wider spread
				startVelocity: 30,   // Faster start speed
				origin: { y: 0.6 },  // Make sure confetti comes from top of the window
				scalar: 1.5,         // Larger size particles
				colors: ['#ff5f5f', '#ffab5f', '#5f9fff', '#ff5fff'],  // Fun, bright colors
				shapes: ['circle', 'square'],  // Mix of shapes
				gravity: 0.7,        // Adjust gravity to make confetti fall slower or faster
				
				drift: 0.5           // Drift sideways a bit
			});
		}, 1000);

		setTimeout(() => {
			confetti({
				particleCount: 700,
				spread: 180,
				angle: 90,
				origin: { y: 0.6 },
				colors: ['#bb0000', '#ffffff', '#333333'], // Customize colors
				shapes: ['circle', 'square'], // Shapes of the confetti pieces
			});
		}, 1300);
	}, []);

	if ( ! user || ! room ) return null;

	const handleClose = () => {
		navigate(`/games/no-thanks`);
	};

	const orderedMembers: MemberInRoom[] = structuredClone(room.members).sort((a: MemberInRoom, b: MemberInRoom) => ( - b.gameResults!.cardsScore + b.gameResults!.chipsScore) - ( - a.gameResults!.cardsScore + a.gameResults!.chipsScore));

	return (
		<div className="no-thanks-dialog-finished">
			<div className="no-thanks-dialog-finished__inner">
				<div className="no-thanks-dialog-finished__close" onClick={ handleClose }>
					<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M12 10.586l-7.293-7.293-1.414 1.414 7.293 7.293-7.293 7.293 1.414 1.414 7.293-7.293 7.293 7.293 1.414-1.414-7.293-7.293 7.293-7.293-1.414-1.414-7.293 7.293z"/></svg>
				</div>
				<div className="no-thanks-dialog-finished__header">
					<span className="no-thanks-dialog-finished__header-icon">👑</span>
					<span className="no-thanks-dialog-finished__header-user"> { orderedMembers[0].nickname }</span> wins
				</div>
				<div className="no-thanks-dialog-finished__members">
					{ orderedMembers.map((member, index) => {
						if ( ! member.gameResults ) return null;

						return (
							<div key={member.memberId} className='no-thanks-dialog-finished__member'>
								<div className="no-thanks-dialog-finished__member-place">
									{ index + 1 }
									-
									{
										index === 0 ? 'st' :
										index === 1 ? 'nd' :
										index === 2 ? 'rd' :
										'th'
									}
								</div>
								<div className="no-thanks-dialog-finished__member-score">
									{ - member.gameResults.cardsScore + member.gameResults.chipsScore }
								</div>
								<div className="no-thanks-dialog-finished__member-info">
									<div className="no-thanks-dialog-finished__member-info-avatar">
										<div className="no-thanks-dialog-finished__member-info-avatar-default"></div>
										<div
											className={
												classnames('no-thanks-dialog-finished__member-info-avatar-image', {
													'no-thanks-dialog-finished__member-info-avatar-image--real': !! member.avatar,
												})
											}
											style={{ backgroundImage: member.avatar && `url(${getAvatarUrl(member.avatar)})` }}
										></div>
									</div>
									{ member.nickname }
								</div>
								<div className="no-thanks-dialog-finished__member-results">
									{ member.gameResults?.currentGameScore < 0 ? member.gameResults.currentGameScore : '+' + member.gameResults.currentGameScore}
									<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg>
									<div className="no-thanks-dialog-finished__member-results-img" />
									<div className="no-thanks-dialog-finished__member-results-new">
										{ member.gameResults.newTotalScore }
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};