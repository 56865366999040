import classnames from 'classnames';
import { MemberInRoom } from "../../../store/useNoThanksRoomStore";
import { sortMembersStartingWithMe } from '../../../../../utils/sortMembersStartingWithMe';
import { useUserStore } from '../../../../../store/useUserStore';
import { Coin } from '../../../components/Coin';
import { getAvatarUrl } from '../../../../../utils/getAvatarUrl';

import './styles.css';

type RightSidebarProps = {
	members: MemberInRoom[];
	turnUserId: string;
	roomStatus: string;
};

export const RightSidebar = ({ members, turnUserId, roomStatus }: RightSidebarProps) => {
	const { user } = useUserStore();

	if ( ! user ) return null;

	return (
		<div className="right-sidebar">
			{sortMembersStartingWithMe(members, user._id).map((member) => (
				<div key={member.memberId} className={`right-sidebar__member colored-line right-sidebar__user_${member.memberId}`}>

					<div className="right-sidebar__member-meta">
						<div className="right-sidebar__member-avatar">
							{ turnUserId === member.memberId && roomStatus !== 'finished' && roomStatus !== 'waiting'
								? (
									<div className="right-sidebar__member-avatar-turn-sand" />
								) : (
									<div
										className={
											classnames('right-sidebar__member-avatar-image', {
												'right-sidebar__member-avatar-image--real': !! member.avatar,
											})
										}
										style={{ backgroundImage: member.avatar && `url(${getAvatarUrl(member.avatar)})` }}
									></div>
								)
							}
						</div>

						<div className="right-sidebar__member-chips">
							<Coin size={ 20 } />
							{ member.chips ?? '?' }
						</div>
					</div>

					<div className="right-sidebar__member-main">
						<div className="right-sidebar__member-nickname colored-child">
							{ member.nickname }
						</div>
						<div className="right-sidebar__member-score">
							<div className="right-sidebar__member-score-img" />
							{
								-member.pickedCards.reduce((acc, card, i) => {
									if (member.pickedCards[ i - 1 ] === card - 1) return acc
									
									return acc + card;
								}, 0)
							}
						</div>
					</div>

					<div className="right-sidebar__member-total-score">
						<div className="right-sidebar__member-total-score-img"></div>
						{ member.memberTotalScoreBeforeGame }
					</div>
				</div>
			))}
		</div>
	);
};