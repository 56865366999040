import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useUserStore } from '../../../store/useUserStore';
import { useRoomStore } from '../store/useNoThanksRoomStore';
import { CardBack } from '../components/CardBack';
import { CardFront } from '../components/CardFront';
import { CardEmpty } from '../components/CardEmpty';
import { RightSidebar } from './components/RightSidebar';
import { Header } from '../../../components/header';
import { sortMembersStartingWithMe } from '../../../utils/sortMembersStartingWithMe';
import { BattlePageDialogWaiting } from './components/DialogWaiting';
import { BattlePageDialogFinished } from './components/DialogFinished';
import { playGameStartSound, playChipThrownSound, playCardTakenSound } from '../sounds';

import './styles.css';

export const NoThanksBattlePage = () => {
	const { roomId } = useParams();
	const { user } = useUserStore();
	const { room, api, initialize, destroy } = useRoomStore();
	const [ isDisabledUI, setIsDisabledUI ] = useState<boolean>(false);
	const [ optedAutoplay, setOptedAutoplay ] = useState<number | undefined>();
	const prevRoomState = useRef<'waiting' | 'in-progress' | 'finished' | undefined>();
	const prevChipsOnTable = useRef<number | undefined>();

	const isMyTurn = room?.turnUserId === user?._id;
	const userInfo = room?.members.find((member: any) => member.memberId === user?._id);
	const currentTurnMemberInfo = room?.members.find((member: any) => member.memberId === room.turnUserId);

	useEffect(() => {
		if ( prevRoomState.current === 'waiting' && room?.state === 'in-progress') {
			playGameStartSound();
		}

		prevRoomState.current = room?.state;
	}, [room?.state]);

	useEffect(() => {
		if (prevChipsOnTable.current !== undefined && room?.chipsOnTable !== prevChipsOnTable.current) { // fix case when user opens "profile", comes back and the game is ALREADY in progress
			if ( room?.chipsOnTable !== 0 ) {
				playChipThrownSound();
			}
		}

		prevChipsOnTable.current = room?.chipsOnTable;
	}, [room?.chipsOnTable]);

	useEffect(() => {
		initialize( roomId! );
	
		return () => destroy( roomId! );
	}, [ roomId, initialize, destroy]);

	const handleButton = async ( action: 'take' | 'skip' ) => {
		if ( ! room?._id ) return;

		setIsDisabledUI(true);

		try {
			if (action === 'take') {
				await api.takeCard(room._id);
			} else if (action === 'skip') {
				await api.skipCard(room._id);
			}
		} catch (error: any) {
			alert(error.message);
		} finally {
			setIsDisabledUI(false);
		}
	};

	useEffect( () => {
		if ( room?.chipsOnTable === undefined) return;

		if ( ! optedAutoplay ) return;

		if ( isMyTurn && ( optedAutoplay === room.chipsOnTable ) ) {
			setOptedAutoplay(undefined);
		}

		if ( isMyTurn && ( room.chipsOnTable < optedAutoplay ) ) {
			handleButton('skip');
		}

	// TODO: investigate. I don't remember was it intentional list of deps or not
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ isMyTurn, optedAutoplay ]);

	useEffect( () => {
		setOptedAutoplay(undefined);
	}, [ room?.currentCard ]);

	// render prev value for 3 seconds to the DOM
	const prevCard = useRef<number | null>(null);
	useEffect(() => {
		if ( ! room?.currentCard ) return;

		if ( ! prevCard.current )  {
			prevCard.current = room.currentCard;
			return;
		}

		playCardTakenSound();

		const isCurrentUserTurn = room.turnUserId === user?._id;
		if ( ! isCurrentUserTurn ) {
			const elPopup = document.createElement('div');
			elPopup.className = 'no-thanks__popup-took-card';

			const realCard = document.querySelector('[data-id="no-thanks__current-card"]')?.getBoundingClientRect();
			elPopup.style.top = realCard?.top! + window.scrollY + 'px';
			elPopup.style.left = realCard?.left + 'px';
			elPopup.style.width = realCard?.width + 'px';
			elPopup.style.height = realCard?.height + 'px';

			const member = room.members.find((member: any) => member.pickedCards.includes(prevCard.current));
			const body = `
				<div class="no-thanks__popup-took-card-nickname">
					${ member?.nickname }
				</div>
				<div class="no-thanks__popup-took-card-connect">
					took
				</div>
				<div class="no-thanks__popup-took-card-number">
					${ prevCard.current?.toString() }
				</div>
			`;
			elPopup.innerHTML = body;

			document.body.appendChild(elPopup);
			setTimeout(() => {
				elPopup.style.opacity = '0';
			}, 2500);

			setTimeout(() => {
				elPopup.remove();
			}, 3000);
		}

		prevCard.current = room.currentCard;

		// intentional
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ room?.currentCard ]);


	if ( !room || !user ) return null;

	const getAutoplayOptions = () => {
		if ( ! userInfo ) return;

		const currentTurnMemberIndex = room.members.findIndex((member: any) => member.memberId === room.turnUserId);
		const myIndex = room.members.findIndex((member: any) => member.memberId === user._id);

		let thisRoundGain: number = room.chipsOnTable;
		if (myIndex >= currentTurnMemberIndex) {
			thisRoundGain += myIndex - currentTurnMemberIndex;
		  } else {
			thisRoundGain += room.members.length - (currentTurnMemberIndex - myIndex);
		}

		const maxOptions = Math.min(userInfo.chips, 6);

		const options = [];

		const stepGain = room.members.length;

		for (let i = 0; i <= maxOptions; i++) {
			options.push(thisRoundGain + stepGain * i);
		}

		return options;
	};

	if (!room) return <div>Loading room details...</div>;

	let headline = null
	if (room.state === 'waiting') {
		headline = <div className="text-lg font-bold">Waiting for the game to start...</div>;
	} else if (room.state === 'finished') {
		headline = <div className="text-lg font-bold">Game is finished</div>;
	} else if ( ! isMyTurn ) {
		headline = <div className="text-lg font-bold"><span className="text-green-500">{ currentTurnMemberInfo?.nickname }</span> must take the card or spend a chip</div>;
	} else if (isMyTurn && currentTurnMemberInfo) {
		headline = (
			<div className="text-lg font-bold">
				<span className="text-green-500">You</span>
				&nbsp;must&nbsp;
				<button
					className="no-thanks__info_panel__button px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
					onClick={ () => handleButton('take') }
					disabled={ isDisabledUI }
				>
					Take the card
				</button>
				{
					currentTurnMemberInfo.chips > 0
					? (
						<>
							&nbsp;or&nbsp;
							<button
								className="no-thanks__info_panel__button px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
								onClick={ () => handleButton('skip') }
								disabled={ isDisabledUI }
							>
								Spend a chip
							</button>
							&nbsp;(of your { currentTurnMemberInfo.chips })
						</>
					) : (
						<>&nbsp;(<span className="text-red">no chips to spend</span>)</>
					)
				}
			</div>
		);
	}

	return (
		<>
			{ room.state === 'waiting' && <BattlePageDialogWaiting /> }
			{ room.state === 'finished' && <BattlePageDialogFinished /> }

			<Header />
			<div className="no-thanks-battle-page">
				<div className="no-thanks-battle-page__body">
					<div className="no-thanks__info_panel">
						{ headline }
					</div>

					<div className="no-thanks__cards_panel">
						<CardBack count={ room.state === 'in-progress' ? room.cardsLeft : null } />
						<CardFront value={ room.currentCard } dataId="no-thanks__current-card" />
						<CardEmpty count={ room.chipsOnTable } />
					</div>

					{
						room.state === 'in-progress' && (
							<div className="no-thanks__autoplay_panel">
								You may choose to automatically play chips on this card until it has a certain number of chips on it.
								{
									getAutoplayOptions()?.map((chip: number, index) => {
										const isActive = optedAutoplay ? optedAutoplay === chip : index === 0;

										return (
											<span
												key={ chip }
												className={
													classNames('no-thanks__autoplay_panel-option', {
														'no-thanks__autoplay_panel-option--selected': isActive,
													})
												}
												onClick={ () => {
													if ( isActive ) return;

													setOptedAutoplay( chip );
												}}
											>
												{ chip }
											</span>
										);
									})
								}
							</div>
						)
					}

					<div className="no-thanks__picked-cards">
						{
							sortMembersStartingWithMe(room.members, user._id, 'Your cards').map((member: any) => (
								<div key={member.memberId} className="no-thanks__picked-cards-line colored-line">
									<div className="no-thanks__picked-cards-nickname colored-child">{ member.nickname }</div>
									<div className="no-thanks__picked-cards-list">
										<div className="no-thanks__picked-cards-list-inner">
											<CardFront value={0} width={ 100 } style={{ width: 0, visibility: 'hidden' }} />
											{ member.pickedCards.map(( card: number, i: number ) => (
												<CardFront
													key={card}
													value={card}
													width={ 100 }
													style={{ zIndex: member.pickedCards.length - i }}
													className={
														classNames({
															'collapse-with-previous-card': member.pickedCards[i - 1] === card - 1,
														})
													}
												/>
											))}
										</div>
									</div>
								</div>
							))
						}
					</div>
				</div>

				<RightSidebar members={ room.members } turnUserId={ room.turnUserId } roomStatus={ room.state } />
			</div>
		</>
	);
};