export const sortMembersStartingWithMe = <T>( members: T[], userId: string, newUserName?: string ): T[] => {
	const myIndex = members.findIndex( ( member: any ) => member.memberId === userId );

	const reorderedList = [ ...members.slice( myIndex ), ...members.slice( 0, myIndex ) ];

	if ( newUserName ) {
		const newUserNameIndex = reorderedList.findIndex( ( member: any ) => member.memberId === userId );

		reorderedList[ newUserNameIndex ] = {
			...reorderedList[ newUserNameIndex ],
			nickname: newUserName
		};
	}

	return reorderedList;
};