import React from 'react';
import { Header } from '../../components/header';
import { useNavigate } from 'react-router-dom';
import ImgNoThanks from '../../img/no_thanks.png';
import ImgDeberc from '../../img/deberc.jpeg';
import { NO_THANKS_MIN_PLAYERS } from '../../games/NoThanks/constants';

import './styles.css';

const games = [
	{
		label: "No, thanks!",
		slug: "no-thanks",
		description: <><br />A simple card game where you try to avoid taking cards with points.<br />The player with the fewest points wins.<br />Minimum { NO_THANKS_MIN_PLAYERS } players, maximum 7 players.<br />Rules: <a href="/en/rules/no-thanks" className="text-blue-800 hover:text-blue-400" onClick={e => e.stopPropagation()}>{process.env.REACT_APP_API_BASE_URL}/en/rules/no-thanks/</a><br /><br /></>,
		img: <img src={ ImgNoThanks } alt="No thanks - screen" />
	},
	{
		label: "Деберц ( Deberc )",
		slug: "Deberc",
		description: <><br />Деберц is a compelling trick-taking game traditionally played by 2 or 4 players. The aim? Outsmart your opponents, collect valuable cards, and score points to win. With cards from Ace to Seven in play, every move counts!</>,
		img: <img src={ ImgDeberc } alt="Deberc - screen" />,
		isComminsSoon: true
	}
];

export const PageHome: React.FC = () => {
	const navigate = useNavigate();

	const handleGameClick = (gameSlug: string) => {
		navigate(`/games/${gameSlug}`);
	};

	return (
		<>
			<div className="home-page__mobile_placeholder">
				<div className="home-page__mobile_placeholder_inner">
					<div className="text-9xl">🎲</div>
					<div className="text-3xl font-semibold text-center text-gray-500 px-4 mt-4">
						Please rotate your device to landscape mode for the best experience,<br />
						but we highly recommend playing on a desktop or laptop computer,
						since some games may not look properly on mobile devices.
					</div>
				</div>
			</div>

			<Header />
			<div className="min-h-screen bg-white flex flex-col items-center pt-10">
				<h2 className="text-2xl font-semibold mb-5">Games</h2>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full max-w-4xl px-4">
					{games.map((game, index) => (
						<div 
							key={index} 
							className="bg-blue-100 rounded-lg p-4 mb-4 shadow hover:bg-blue-200 transition-colors cursor-pointer flex flex-col"
							onClick={() => {
								if ( game.isComminsSoon ) {
									alert('This game is coming soon');
									return;
								}

								handleGameClick(game.slug)
							}}
						>
							<h3 className="text-xl font-medium">{game.label}</h3>
							<p className="text-sm text-gray-600">{game.description}</p>
							<div style={{marginTop: 'auto'}}>
								{game.img}
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};