import '@ungap/structured-clone';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PR } from './router/ProtectedRoute';
import { Page404 } from './pages/Page404';
import { PageHomeLanding } from './pages/HomeLanding';
import { PageHome } from './pages/Home';
import { PageSignIn } from './pages/SignIn';
import { PageSignUp } from './pages/SignUp';
import { RequestResetPassword } from './pages/RequestResetPassword';
import { ResetPassword } from './pages/ResetPassword';
import { RulesNoThanks } from './pages/RulesNoThanks';
import { PageMe } from './pages/Me';
import { NoThanksHomePage } from './games/NoThanks/HomePage';
import { NoThanksBattlePage } from './games/NoThanks/BattlePage';
import { useUserStore } from './store/useUserStore';
import { DebercHomePage } from './games/Deberc/HomePage';
import { DebercBattlePage } from './games/Deberc/BattlePage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Loader = () => (
	<div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
		<div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-12 w-12 mb-4"></div>
	</div>
);

const App: React.FC = () => {
	const { fetchUserInfo, user, isLoadingUser } = useUserStore();

	useEffect(() => {
		if ( user ) return;

		fetchUserInfo().catch(console.log);
	}, [fetchUserInfo, user]);

	return (
		<>
			{ isLoadingUser && <Loader /> }
			<Router>
				<Routes>
					<Route path="/" element={user ? <PageHome /> : <PageHomeLanding />} />
					<Route path="/signin" element={<PageSignIn />} />
					<Route path="/signup" element={<PageSignUp />} />
					<Route path="/request-reset-password" element={<RequestResetPassword />} />
					<Route path="/reset-password/:token" element={<ResetPassword />} />
					<Route path="/en/rules/no-thanks/" element={<RulesNoThanks />} />

					<Route path="/me" element={<PR><PageMe /></PR>} />
					<Route path="/games/no-thanks" element={<PR><NoThanksHomePage /></PR>} />
					<Route path="/games/no-thanks/:roomId" element={<PR><NoThanksBattlePage /></PR>} />
					<Route path="/games/deberc" element={<PR><DebercHomePage /></PR>} />
					<Route path="/games/deberc/:roomId" element={<PR><DebercBattlePage /></PR>} />
					<Route path="*" element={<Page404 />} />
				</Routes>
			</Router>
			<ToastContainer />
		</>
	);
};

export default App;